import "./polyfills";
import "./render/shader/ShaderChunk";
import Application from "./Application";
import global from "./global";
import Config from "../config";

const app = new Application(document.getElementById("container"), {
    server: Config.server_host,
    enableCache: true,
    ai16z: process.env.DEPLOYMENT_TYPE === "ai16z",
});
global.app = app;
